import React, { useEffect } from "react";
import "../../styles/CometChatLogin/CometChatLogin.css";
import { useNavigate } from "react-router-dom";
import cometChatLogo from "../../assets/cometchat_logo.svg";
import cometChatLogoDark from "../../assets/cometchat_logo_dark.svg";
import {
  CometChatUIKit,
  CometChatUIKitLoginListener,
} from "@cometchat/chat-uikit-react";
import { COMETCHAT_CONSTANTS } from "../../AppConstants";

const CometChatLogin = () => {
  const navigate = useNavigate();
  const isDarkMode = document.querySelector('[data-theme="dark"]')
    ? true
    : false;

  // Fetch the authToken on component mount
  useEffect(() => {
    // Check if already logged in
    if (CometChatUIKitLoginListener.getLoggedInUser()) {
      navigate("/home", { replace: true });
    } else {
      // Get the authToken from the API
      fetch(COMETCHAT_CONSTANTS.AUTH_TOKEN_URL)
        .then((response) => response.json())
        .then((res) => {
          if (res.authToken) {
            // Use CometChat's loginWithAuthToken method to log the user in
            CometChatUIKit.loginWithAuthToken(res.authToken)
              .then((loggedInUser) => {
                console.log("Login successful, loggedInUser:", loggedInUser);
                navigate("/home"); // Navigate to the home page
              })
              .catch((error) => {
                console.error("Login failed:", error);
              });
          } else {
            console.error("No authToken received");
          }
        })
        .catch((error) => {
          console.error("Error fetching authToken:", error);
        });
    }
  }, [navigate]);

  return (
    <div className="cometchat-login">
      <img
        src={isDarkMode ? cometChatLogoDark : cometChatLogo}
        alt="CometChat Logo"
        className="cometchat-logo"
      />
      <div className="loading">Loading...</div>
    </div>
  );
};

export default CometChatLogin;
